// export const BaseURL = "https://apistaging.inclass.app/api/";
// export const BaseURL = "https://api.inclass.app/api/";
export const BaseURL = process.env.REACT_APP_BASE_URL;

// export const BaseURL = "https://apicircle2.inclass.app/api/";
export const CoursesURL = `v2/admin/courses`;
export const managedCourse='v2/managed-courses';
export const LookupsURL = `lookups`;
export const PresignedURL = `v1/presigned_url`;
export const instructor = `v2/users/instructor`;
export const student = `v2/users/student`;
export const QUESTION_BANK = `question_banks`;
const currentURL = window.location.href;
const url = new URL(currentURL);
const hostname = url.hostname;
const firstDotIndex = hostname.indexOf(".");
// export const Organization_name = hostname.substring(0, firstDotIndex).replace('adminstaging','')||'circles';
// export const Organization_name = hostname.substring(0, firstDotIndex).replace('admin','')||'circles';
export const Organization_name = process.env.REACT_APP_ORGANIZATION_NAME;
export const Organization_id = process.env.REACT_APP_ORGANIZATION_ID;

// export const Organization_name = hostname.substring(0, firstDotIndex).replace('admin2','')||'circles';
// export const Organization_name = hostname.substring(0, firstDotIndex).replace('admin2','')||'circles';
/*
***staging deployment***
scp -r build deploy@3.79.86.114:~/dashboard_deployment/server_build
ip: 3.79.86.114 dashboard_deployment/server_build
BaseURL = "https://apistaging.inclass.app/api/";
Organization_name = hostname.substring(0, firstDotIndex).replace('adminstaging','')||'circles';
https://adminstaging.inclass.app/



***inclass production server***
scp -r build deploy@3.74.136.168:~/dashboard_deployment/server_build
ip:3.74.136.168 dashboard_deployment/server_build
BaseURL = "https://api.inclass.app/api/";
Organization_name = hostname.substring(0, firstDotIndex).replace('admin','')||'circles';
    https://admin.inclass.app/



***circles production**
scp -r build deploy@18.153.231.126:~/dashboard_deployment/server_build
ip:18.153.231.126 dashboard_deployment/server_build
BaseURL = "https://apicircle2.inclass.app/api/";
Organization_name = hostname.substring(0, firstDotIndex).replace('admin2','')||'circles';
https://circlesadmin2.inclass.app/
*/ 

// ***royal production**
// https://dashboardroyal.inclass.app/